import '../css/portraitFrame.css';
import IPortraitProps from './IPortraitProps';
import Portrait from './Portrait';

// Lays out the frames for all grouped portraits
function PortraitFrame(props: IPortraitProps[]) {
    if (props.length === 0) {
        return <div className='portrait-frame-empty'>No Councillors in this ward are facing re-election in 2021.</div>;
    }
    return <div className='portrait-frame'>{props.map((p) => Portrait(p))}</div>;
}

export default PortraitFrame;
