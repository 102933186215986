export const wadeDecampoQuestion1 = `I have read the executive summary of the report but not the full body of the report.`;
export const wadeDecampoQuestion2 = `No I do not oppose a scheme of some type. If we are to be the food bowl in the south of the state then we need more water. That needs to be on a sustainable basis and with a project that the suits a whole community not a select few.
There are options that won’t disadvantage any of the land holders in the shire of Manjimup and the licences that they are entitled to.

Any scheme that I would look at supporting would be essentially not disadvantage any licence holder or surface catchment scheme that currently exists for licences that are now in existence or future potential licences that science may allow whether it be catch and store in a wet year (i.e. secondary licence) or yearly usable licence.
Any scheme that is considered should be down stream from all farm land so that there is no disadvantage to land holders.
That could be close to ocean or, in the case of the Warren, just south of Pemberton. There then could be a system of integrated pipe work that fills numerus dams on properties that express interest.

In NO case do I or would I support decoupling water that is capture and store on properties that is licenced. I also would strongly oppose charging for that water.

In the case, though, of an integrated water scheme being supplied from multiple river sources then I wouldn’t see an issue with shifting water from one property to another.
This water would also have a value per meg as it would have significant infrastructure attached to it.

The one thing that I do know is that to have future expansion in horticulture in the Southern Forest area we need more water. That water should be only captured on a sustainable basis.`;
export const wadeDecampoQuestion3 = `I am absolutely opposed to water trading of surface water and any charge for the use of water from surface catchment of any type. 
I do though endorse the charging or trading of water from any constructed scheme.`;
