import '../css/councillor.css';
import IPortraitProps from './IPortraitProps';
import MainNavBar from './MainNavBar';
import Footer from './Footer';
import tick from '../img/tick.svg';
import unknown from '../img/unknown.svg';
import cross from '../img/cross.svg';
import { determineQuadrantClass } from './utils';
import ContactType from './ContactType';
import IDetailSection from './IDetailSection';
import FinancialInterest from './FinancialInterest';

function Councillor(props: IPortraitProps) {
    var activeFinancialInterest = props.financialInterest === FinancialInterest.Active;
    var relativeFinancialInterest = props.financialInterest === FinancialInterest.Relative;
    const question1Class = determineQuadrantClass(
        activeFinancialInterest ? false : relativeFinancialInterest ? undefined : true
    );
    const question2Class = determineQuadrantClass(props.question2);
    const question3Class = determineQuadrantClass(props.question3);
    const question4Class = determineQuadrantClass(props.question4);
    return (
        <div>
            {MainNavBar({ ward: null })}
            {renderTitleAndName(props)}
            {/* {renderTermExpires(props)}
            {renderUpForElectionOrSafe(props)} */}
            <div className='councillor-body'>
                <div className='councillor-answers-container'>
                    <div className='councillor-image' style={{ backgroundImage: `url(${props.photo})` }}>
                        <div className={`top-left quadrant ${question4Class}`}></div>
                        <div className={`top-right quadrant ${question1Class}`}></div>
                        <div className={`bottom-right quadrant ${question2Class}`}></div>
                        <div className={`bottom-left quadrant ${question3Class}`}></div>
                    </div>
                    {renderQuestion1(props)}
                    {renderQuestion2(props.question2)}
                    {renderQuestion3(props.question3)}
                    {renderQuestion4(props.question4)}

                    {renderShareContact(props)}
                    {props.details.map((d) => {
                        return (
                            <div key={d.key}>
                                <div className='councillor-body-heading'>{d.heading}</div>
                                {renderNoteIfSet(d)}
                                <div className='councillor-body-text'>{d.body}</div>
                                <div className='councillor-body-hr' />
                            </div>
                        );
                    })}
                </div>
            </div>
            {renderEmailContact(props)}
            {Footer()}
        </div>
    );
}

function renderNoteIfSet(details: IDetailSection) {
    if (details.note) {
        return <div className='councillor-body-note'>Note: {details.note}</div>;
    }
    return null;
}

function renderTitleAndName(props: IPortraitProps) {
    let title = props.councillor ? 'Councillor' : `Candidate #${props.ballotNumber} -`;
    if (!props.councillor && !props.ballotNumber) {
        title = 'Candidate ';
    }
    return (
        <div className='councillor-name'>
            {title} {props.name}
        </div>
    );
}

// function renderTermExpires(props: IPortraitProps) {
//     let message = `Term expires ${props.termExpires}`;
//     if (!props.councillor) {
//         message = 'Candidate in 2021';
//     }

//     return <div className='councillor-term'>{message}</div>;
// }

// function renderUpForElectionOrSafe(props: IPortraitProps) {
//     let message = 'UP FOR RE-ELECTION';
//     let classes = 'councillor-seat-unsafe';
//     if (!props.councillor) {
//         message = '';
//     } else if (props.termExpires !== 2021) {
//         message = 'SECURE SEAT';
//         classes = 'councillor-seat-safe';
//     }
//     return <div className={classes}>{message}</div>;
// }

function renderEmailContact(props: IPortraitProps) {
    return (
        <div className='councillor-email-container'>
            <div className='councillor-contact-message-container'>
                <span className='councillor-contact-message'>Do you have more questions for {props.name}?</span>
            </div>
            <a className='councillor-email-button' href={`mailto:${props.email}`}>
                Email them directly!
            </a>
        </div>
    );
}

function renderIcon(icon?: string) {
    if (!icon) {
        return null;
    }
    return <div className='portrait-icon' style={{ backgroundImage: `url(${icon})` }} />;
}

function renderQuestion1(props: IPortraitProps) {
    if (!props.councillor) {
        return null;
    }

    const q1 = props.question1;
    let icon: string | undefined = tick;
    let highlightClass = 'good';
    let action = 'Voted against';
    if (q1 === undefined) {
        icon = unknown;
        highlightClass = 'none';
        action = 'Unknown vote';
    } else if (q1 === null) {
        icon = undefined;
        highlightClass = 'unknown';
        action = "Didn't vote on";
    } else if (!q1) {
        icon = cross;
        highlightClass = 'bad';
        action = 'Voted for';
    }
    return (
        <div className='councillor-row-left-aligned portrait-table-with-small-gap'>
            {renderIcon(icon)}
            <div>
                <span className={highlightClass}>{action}</span> the motion to support SFIC fundraising grant request
                for the SFIS on 21/03/2019.
            </div>
        </div>
    );
}

function renderQuestion2(q2?: Boolean) {
    let icon = tick;
    let highlightClass = 'good';
    let action = 'Has';
    if (q2 === undefined) {
        icon = unknown;
        highlightClass = 'unknown';
        action = 'Might have';
    } else if (!q2) {
        icon = cross;
        highlightClass = 'bad';
        action = 'Has not';
    }
    return (
        <div className='councillor-row-left-aligned portrait-table-with-tiny-gap'>
            {renderIcon(icon)}
            <div>
                <span className={highlightClass}>{action}</span> read the CSIRO report "Donnelly River model review" by
                Justin Hughes.
            </div>
        </div>
    );
}

function renderQuestion3(q3?: Boolean) {
    let icon = tick;
    let highlightClass = 'good';
    let action = 'Opposes';
    if (q3 === undefined) {
        icon = unknown;
        highlightClass = 'unknown';
        action = 'Might support';
    } else if (!q3) {
        icon = cross;
        highlightClass = 'bad';
        action = 'Supports';
    }
    return (
        <div className='councillor-row-left-aligned portrait-table-with-tiny-gap'>
            {renderIcon(icon)}
            <div>
                <span className={highlightClass}>{action}</span> an SFIS style water scheme.
            </div>
        </div>
    );
}

function renderQuestion4(q4?: Boolean) {
    let icon = tick;
    let highlightClass = 'good';
    let action = 'Opposes';
    if (q4 === undefined) {
        icon = unknown;
        highlightClass = 'unknown';
        action = 'Might support';
    } else if (!q4) {
        icon = cross;
        highlightClass = 'bad';
        action = 'Supports';
    }
    return (
        <div className='councillor-row-left-aligned portrait-table-with-tiny-gap'>
            {renderIcon(icon)}
            <div>
                <span className={highlightClass}>{action}</span> water trading markets.
            </div>
        </div>
    );
}

function renderShareContact(props: IPortraitProps) {
    if (props.contactType !== ContactType.Share) {
        return null;
    }
    const agentType = props.councillor ? 'councillor' : 'candidate';
    return (
        <div>
            <a
                className='councillor-share-button'
                href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fvote-clearly.info%2F%23%2Fmanjimup%2F${agentType}%2F${props.shareUrl}&amp;`}
            >
                Share your support for {props.name}!
            </a>
        </div>
    );
}

export default Councillor;
