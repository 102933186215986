import '../css/ward.css';
import PortraitFrame from './PortraitFrame';
import IPortraitProps from './IPortraitProps';
import MainNavBar from './MainNavBar';
import Footer from './Footer';
import ShireWard from './ShireWard';

interface Props {
    ward: ShireWard;
    councillors: IPortraitProps[];
}

function Ward(props: Props) {
    return (
        <div>
            {MainNavBar({ ward: props.ward })}
            <div className='ward-name'>{`${ShireWard[props.ward]} Ward`}</div>
            {PortraitFrame(props.councillors)}
            {Footer()}
        </div>
    );
}

export default Ward;
