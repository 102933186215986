import '../css/portrait.css';
import IPortraitProps from './IPortraitProps';
import tick from '../img/tick.svg';
import unknown from '../img/unknown.svg';
import cross from '../img/cross.svg';
import ContactType from './ContactType';
import { Link } from 'react-router-dom';
import { ReactElement } from 'react';
import { determineQuadrantClass } from './utils';
import FinancialInterest from './FinancialInterest';

// An individual portrait
function Portrait(props: IPortraitProps) {
    var activeFinancialInterest = props.financialInterest === FinancialInterest.Active;
    var relativeFinancialInterest = props.financialInterest === FinancialInterest.Relative;
    const question1Class = determineQuadrantClass(
        activeFinancialInterest ? false : relativeFinancialInterest ? undefined : true
    );
    const question2Class = determineQuadrantClass(props.question2);
    const question3Class = determineQuadrantClass(props.question3);
    const question4Class = determineQuadrantClass(props.question4);

    const quadrants = (
        <div>
            <div className={`top-left quadrant ${question4Class}`}></div>
            <div className={`top-right quadrant ${question1Class}`}></div>
            <div className={`bottom-right quadrant ${question2Class}`}></div>
            <div className={`bottom-left quadrant ${question3Class}`}></div>
        </div>
    );

    return (
        <div key={props.name} className='portrait'>
            <div className='portrait-image' style={{ backgroundImage: `url(${props.photo})` }}>
                {conditionallyRenderLink(props, quadrants)}
            </div>

            {renderTitleAndName(props)}
            {/* {renderTermExpires(props)} */}
            {/* {renderUpForElectionOrSafe(props)} */}
            {renderHr()}
            {renderResponseReceived(props.responded)}
            {renderAnswersReceived(props.answered)}
            {renderHr()}
            {renderFinancialInterest(props)}
            {renderHr()}
            {renderQuestion1(props.question1)}
            {renderQuestion2(props.question2)}
            {renderQuestion3(props.question3)}
            {renderQuestion4(props.question4)}
            {renderHr()}
            {renderEmailContact(props)}
            {renderShareContact(props)}
        </div>
    );
}

function renderTitleAndName(props: IPortraitProps) {
    let title = props.councillor ? 'Cr ' : `#${props.ballotNumber} `;
    if (!props.councillor && !props.ballotNumber) {
        title = '';
    }
    const view = (
        <div className='portrait-row-center-aligned portrait-table-with-large-gap portrait-name'>
            {title}
            {props.name}
        </div>
    );
    return conditionallyRenderLink(props, view);
}

function conditionallyRenderLink(props: IPortraitProps, view: ReactElement) {
    if (props.url) {
        return (
            <Link to={props.url} className='no-link-styling'>
                {view}
            </Link>
        );
    }
    return view;
}

// function renderTermExpires(props: IPortraitProps) {
//     let message = `Term expires ${props.termExpires}`;
//     if (!props.councillor) {
//         message = 'Candidate in 2021';
//     }

//     return (
//         <div className='portrait-row-center-aligned portrait-table-with-tiny-gap'>
//             <div className='portrait-row-term-expires'>{message}</div>
//         </div>
//     );
// }

// function renderUpForElectionOrSafe(props: IPortraitProps) {
//     let message = 'UP FOR RE-ELECTION';
//     let classes = 'portrait-row-term-expires-unsafe';
//     if (!props.councillor) {
//         message = 'CANDIDATE';
//     } else if (props.termExpires !== 2021) {
//         message = 'SECURE SEAT';
//         classes = 'portrait-row-term-expires-safe';
//     }
//     return (
//         <div className='portrait-row-center-aligned'>
//             <div className={classes}>{message}</div>
//         </div>
//     );
// }

function renderQuestion1(q1?: Boolean | null) {
    let icon: string | undefined = tick;
    let highlightClass = 'good';
    let action = 'Voted against';
    if (q1 === undefined) {
        icon = unknown;
        highlightClass = 'none';
        action = 'Unknown vote';
    } else if (q1 === null) {
        icon = undefined;
        highlightClass = 'unknown';
        action = "Didn't vote on";
    } else if (!q1) {
        icon = cross;
        highlightClass = 'bad';
        action = 'Voted for';
    }
    return (
        <div className='portrait-row-left-aligned portrait-table-with-small-gap'>
            {renderIcon(icon)}
            <div>
                <span className={highlightClass}>{action}</span> SFIS support in 2019.
            </div>
        </div>
    );
}

function renderIcon(icon?: string) {
    if (!icon) {
        return null;
    }
    return <div className='portrait-icon' style={{ backgroundImage: `url(${icon})` }} />;
}

function renderQuestion2(q2?: Boolean) {
    let icon = tick;
    let highlightClass = 'good';
    let action = 'Has';
    if (q2 === undefined) {
        icon = unknown;
        highlightClass = 'unknown';
        action = 'Might have';
    } else if (!q2) {
        icon = cross;
        highlightClass = 'bad';
        action = 'Has not';
    }
    return (
        <div className='portrait-row-left-aligned portrait-table-with-tiny-gap'>
            {renderIcon(icon)}
            <div>
                <span className={highlightClass}>{action}</span> read the CSIRO report.
            </div>
        </div>
    );
}

function renderQuestion3(q3?: Boolean) {
    let icon = tick;
    let highlightClass = 'good';
    let action = 'Opposes';
    if (q3 === undefined) {
        icon = unknown;
        highlightClass = 'unknown';
        action = 'Might support';
    } else if (!q3) {
        icon = cross;
        highlightClass = 'bad';
        action = 'Supports';
    }
    return (
        <div className='portrait-row-left-aligned portrait-table-with-tiny-gap'>
            {renderIcon(icon)}
            <div>
                <span className={highlightClass}>{action}</span> an SFIS style scheme.
            </div>
        </div>
    );
}

function renderQuestion4(q4?: Boolean) {
    let icon = tick;
    let highlightClass = 'good';
    let action = 'Opposes';
    if (q4 === undefined) {
        icon = unknown;
        highlightClass = 'unknown';
        action = 'Might support';
    } else if (!q4) {
        icon = cross;
        highlightClass = 'bad';
        action = 'Supports';
    }
    return (
        <div className='portrait-row-left-aligned portrait-table-with-tiny-gap'>
            {renderIcon(icon)}
            <div>
                <span className={highlightClass}>{action}</span> water trading markets.
            </div>
        </div>
    );
}

function renderFinancialInterest(props: IPortraitProps) {
    let financialInterestString = 'NO';
    let financialInterestClass = 'YES';
    if (props.financialInterest === FinancialInterest.Active) {
        financialInterestString = 'YES';
        financialInterestClass = 'NO'; // Invert string class intentionally here.
    } else if (props.financialInterest === FinancialInterest.Passive) {
        financialInterestString = 'YES';
        financialInterestClass = 'PASSIVE';
    } else if (props.financialInterest === FinancialInterest.Relative) {
        financialInterestString = 'MAYBE';
        financialInterestClass = 'PASSIVE';
    }
    return (
        <div className='portrait-row-center-aligned portrait-table-with-small-gap'>
            <span className='response-or-answer'>
                Declared Financial Interest: <span className={financialInterestClass}>{financialInterestString}</span>
            </span>
        </div>
    );
}

function renderResponseReceived(responded: Boolean) {
    const responsedString = responded ? 'YES' : 'NO';
    return (
        <div className='portrait-row-center-aligned portrait-table-with-small-gap'>
            <span className='response-or-answer'>
                Responded to email: <span className={responsedString}>{responsedString}</span>
            </span>
        </div>
    );
}

function renderAnswersReceived(answered: Boolean) {
    const answeredString = answered ? 'YES' : 'NO';
    return (
        <div className='portrait-row-center-aligned portrait-table-with-tiny-gap'>
            <span className='response-or-answer'>
                Answered questions: <span className={answeredString}>{answeredString}</span>
            </span>
        </div>
    );
}

function renderHr() {
    return (
        <div className='portrait-row-center-aligned portrait-table-with-small-gap'>
            <div className='hr'></div>
        </div>
    );
}

function renderEmailContact(props: IPortraitProps) {
    if (props.contactType !== ContactType.Email) {
        return null;
    }
    return (
        <div className='bottom-middle-container'>
            <div className='portrait-row-center-aligned portrait-table-with-small-gap'>
                <span className='contact-message'>Want to know more about {props.name}'s position on the SFIS?</span>
            </div>
            <a className='email-button' href={`mailto:${props.email}`}>
                Email them directly!
            </a>
        </div>
    );
}

function renderShareContact(props: IPortraitProps) {
    if (props.contactType !== ContactType.Share) {
        return null;
    }
    const agentType = props.councillor ? 'councillor' : 'candidate';
    return (
        <div className='bottom-middle-container'>
            <div className='portrait-row-center-aligned portrait-table-with-small-gap'>
                <span className='contact-message'>Are you happy with {props.name}'s position on the SFIS?</span>
            </div>
            <a
                className='share-button'
                href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fvote-clearly.info%2F%23%2Fmanjimup%2F${agentType}%2F${props.shareUrl}&amp;`}
            >
                Share your support!
            </a>
        </div>
    );
}

export default Portrait;
