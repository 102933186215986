import { Link } from 'react-router-dom';
import '../css/nav.css';
import ShireWard from './ShireWard';

interface Props {
    ward?: ShireWard | null;
}

// The main nav bar (top)
function MainNavBar(props: Props) {
    const aboutClass = props.ward === undefined ? 'navbar-button-selected' : 'navbar-button-unselected';
    const centralClass = props.ward === ShireWard.Central ? 'navbar-button-selected' : 'navbar-button-unselected';
    // const northClass = props.ward === ShireWard.North ? 'navbar-button-selected' : 'navbar-button-unselected';
    const eastClass = props.ward === ShireWard.East ? 'navbar-button-selected' : 'navbar-button-unselected';
    // const southClass = props.ward === ShireWard.South ? 'navbar-button-selected' : 'navbar-button-unselected';
    const westClass = props.ward === ShireWard.West ? 'navbar-button-selected' : 'navbar-button-unselected';
    // const coastalClass = props.ward === ShireWard.Coastal ? 'navbar-button-selected' : 'navbar-button-unselected';

    return (
        <div className='navbar-container'>
            <Link to='/manjimup/our-concerns'>
                <button className={aboutClass} onClick={() => {}}>
                    Our Concerns
                </button>
            </Link>
            <Link to='/manjimup/ward/central'>
                <button className={centralClass} onClick={() => {}}>
                    Central Ward
                </button>
            </Link>
            {/* <Link to='/manjimup/ward/north'>
                <button className={northClass} onClick={() => {}}>
                    North Ward
                </button>
            </Link> */}
            <Link to='/manjimup/ward/east'>
                <button className={eastClass} onClick={() => {}}>
                    East Ward
                </button>
            </Link>
            {/* <Link to='/manjimup/ward/south'>
                <button className={southClass} onClick={() => {}}>
                    South Ward
                </button>
            </Link> */}
            <Link to='/manjimup/ward/west'>
                <button className={westClass} onClick={() => {}}>
                    West Ward
                </button>
            </Link>
            {/* <Link to='/manjimup/ward/coastal'>
                <button className={coastalClass} onClick={() => {}}>
                    Coastal Ward
                </button>
            </Link> */}
        </div>
    );
}

export default MainNavBar;
