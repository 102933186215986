enum ShireWard {
    Central,
    North,
    East,
    South,
    West,
    Coastal,
}

export default ShireWard;
