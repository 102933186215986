import '../css/nav.css';
import '../css/footer.css';
import { Link } from 'react-router-dom';
import email from '../img/email.txt';

// The footer
function Footer() {
    return (
        <div>
            <div className='footer-top'>
                <div className='footer-section'>
                    <span className='footer-section-title'>Our mission</span>
                    <div className='footer-section-hr' />
                    <div className='footer-section-emphasis'>
                        We care deeply about local family farms, water access and the environment!
                    </div>
                    <div className='footer-section-normal'>
                        The dual threats of the SFIS (Southern Forest Irrigation Scheme) and water markets have inspired
                        us to help protect Manjimup and the South West from both!
                    </div>
                    <div className='footer-section-normal'>
                        <Link className='footer-link' to='/manjimup/our-concerns'>
                            Read about our concerns here.
                        </Link>
                    </div>
                </div>
                <div className='footer-section'>
                    <span className='footer-section-title'>How this works</span>
                    <div className='footer-section-hr' />
                    <div className='footer-section-emphasis'>
                        Each Councillor was emailed a polite and formal request for their position on the SFIS and water
                        markets at 10:56pm 31/08/2021 AWST;{' '}
                        <a className='footer-link' href={`${window.location.origin}${email}`}>
                            read the email here.
                        </a>
                    </div>
                    <div className='footer-section-normal'>
                        Once a Councillor responds this website is updated to reflect their position, allowing you to
                        see which councillors or candidates will take a strong stand against these threats and those
                        that likely will not.
                    </div>
                </div>
                <div className='footer-section'>
                    <span className='footer-section-title'>Our promise</span>
                    <div className='footer-section-hr' />
                    <div className='footer-section-emphasis'>
                        This website is about fairness, clarity and providing visibility of the Councillors positions on
                        these critical topics.
                    </div>
                    <div className='footer-section-normal'>
                        I will update this website ASAP after Councillors provide a statement of their position. Once
                        statements are received I will include these in the Councillors words{' '}
                        <span className='no-edits'>without any edits.</span>
                    </div>
                </div>
            </div>
            <div className='footer-bottom'>
                <span className='footer-blurb'>
                    We want to help everyone to vote clearly and send a strong message this local election, against the
                    SFIS and a potential water market in the South West!
                </span>
                <a className='email-us-button' href={`mailto:j.connor.au@protonmail.com`}>
                    Email Us: j.connor.au@protonmail.com
                </a>
            </div>
        </div>
    );
}

export default Footer;
