export const cliffWinfieldQuestion1 = `With regard to your first question – below is the motion passed, and I would point out the motion was whether Council supported the application for funding. I did not vote on this item.

That Council:
1. Support the Southern Forest Irrigation Co-operative’s application for grant funding from the Australian Government for funding towards the establishment of the Southern Forest Irrigation Scheme subject to:
    a)    The Southern Forest Irrigation Co-operative obtaining all necessary approvals, including but not limited to:-
        a.    All required environmental approvals;
        b.    Approval to modify the existing bed and banks of the Donnelly River; and
        c.    A License to Take Water under the Rights in Water and Irrigation Act.
    b)   Water allocated to the Southern Forest Irrigation Scheme project being in addition to the water allocation for private farmers in the Donnelly Catchment.
2. Authorise the Chief Executive Officer to prepare correspondence to support the application referred to in point 1 above.`;
export const cliffWinfieldQuestion2 = ``;
export const cliffWinfieldQuestion3 = ``;
export const cliffWinfieldQuestion4 = ``;
