import '../css/concerns.css';
import MainNavBar from './MainNavBar';
import Footer from './Footer';
import WhoAreWe from './WhoAreWe';
import soldDownTheRiverCover from '../img/sold_down_the_river.png';

function OurConcerns() {
    return (
        <div className=''>
            {MainNavBar({ ward: undefined })}
            <div className='concerns-title'>Our Concerns</div>
            <div className='concerns-issues'>
                <div className='concerns-heading'>
                    The SFIS proposal is based on the flawed idea that new water from forested flows was available, as
                    the Donnelly River model review showed the modelling was incorrect and the proposed water simply
                    doesn't exist.
                </div>
                <a
                    className='concerns-link'
                    href='https://www.agric.wa.gov.au/sites/gateway/files/Donnelly%20river%20model%20review%20-%20CSIRO_0.pdf'
                >
                    <div className='concerns-link-div'>Donnelly River model review (CSIRO)</div>
                </a>
                <div className='concerns-note'>Justin Hughes 31 May 2021</div>
                <div className='concerns-body-normal'>
                    Unfortunately because there is not enough transparency we cannot say for sure; but it seems that the
                    SFIS approach is mutating towards identifying underutilised allocations and using them to supply a
                    market based scheme. DWER suggests 30 to 50 percent of allocations are currently underutilised.
                    However, as DWER produced the flawed modelling behind the initial SFIS plan, I'm not inclined to
                    trust their assertion without appropriate independent third-party validation. As planned, the SFIS
                    creates a water market that moves water between different catchments and would see water allocations
                    decoupled from land ownership, creating a market ripe for speculation. Stated plainly, we are facing
                    a drying and changing climate; we are dealing with complicated ecosystems which have multiple
                    inter-dependencies, many of which are not fully understood. We need voices advocating for restraint
                    in what we take and for the environment, otherwise it comes last in every decision!
                </div>
                <div className='concerns-body-normal'>
                    We need only look at the economic and environmental destruction over east in the Murray Darling
                    river system; caused primarily by the toxic influence of privatised water and market speculators to
                    know that water markets are terrible news for the vast majority of farmers, locals and our
                    enviroment!
                </div>
            </div>
            <div className='concerns-title-secondary'>Understanding the threat of water markets</div>
            <div className='video-container'>
                <div className='video-container-inner'>
                    <iframe
                        src='https://www.youtube.com/embed/z2jRb-V50Jk'
                        title='YouTube video player'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    ></iframe>
                    <a className='concerns-link' href='https://www.youtube.com/watch?v=z2jRb-V50Jk'>
                        <div className='video-link-container video-inner-container'>
                            "A Train Wreck": Maryanne Slattery On The Mismanagement Of The Murray-Darling Basin
                        </div>
                    </a>
                    <div className='concerns-note video-inner-container'>
                        This episode was broadcast on 28th October 2019.
                    </div>
                    <div className='video-link-container video-summary video-inner-container'>
                        "The Basin Plan is a train wreck": Water Researcher Maryanne Slattery calls out the
                        mismanagement of the Murray-Darling Basin.
                    </div>
                </div>
                <div className='video-container-inner'>
                    <iframe
                        width='375'
                        height='236'
                        src='https://www.youtube.com/embed/glgCA9WmqkI?start=21'
                        title='YouTube video player'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    ></iframe>
                    <a className='concerns-link' href='https://youtu.be/glgCA9WmqkI?t=21'>
                        <div className='video-link-container video-inner-container'>
                            Blood Water: the war for Australia's water
                        </div>
                    </a>
                    <div className='concerns-note video-inner-container'>Premiered on 12 Jul 2021.</div>
                    <div className='video-link-container video-summary video-inner-container'>
                        Topics covered include failures of water-market regulation, speculators, market-manipulation,
                        flood-plain harvesting, economic impacts in affected towns and communities, environmental
                        destruction in the rivers sytem.
                    </div>
                </div>
            </div>
            <div className='concerns-reading'>
                <div className='concerns-further-reading'>
                    <div>
                        <div className='concerns-heading-big'>Further reading: Murray Darling</div>
                        <a
                            className='concerns-link'
                            href='https://www.michaelwest.com.au/going-nuts-murray-darlings-unbelievably-beautiful-story-for-investors-a-nightmare-for-farmers-environment/'
                        >
                            <div className='concerns-link-div'>
                                Going Nuts: Murray Darling’s “unbelievably beautiful story” for investors a nightmare
                                for farmers, environment.
                            </div>
                        </a>
                        <div className='concerns-note'>Callum Foote - August 1, 2021</div>
                        <div className='portrait-table-with-small-gap'>
                            <div className='hr'></div>
                        </div>
                        <a
                            className='concerns-link'
                            href='https://www.michaelwest.com.au/scientifically-indefensible-how-a-13-billion-political-fix-is-killing-the-murray-darling-basin/'
                        >
                            <div className='concerns-link-div'>
                                “Scientifically Indefensible”: how a $13 billion political fix is killing the Murray
                                Darling Basin
                            </div>
                        </a>
                        <div className='concerns-note'>Richard Beasley - April 21, 2021</div>
                        <div className='portrait-table-with-small-gap'>
                            <div className='hr'></div>
                        </div>
                        <a
                            className='concerns-link'
                            href='https://www.michaelwest.com.au/gross-negligence-walker-throws-the-book-at-nsw-feds-over-murray-darling/'
                        >
                            <div className='concerns-link-div'>
                                Gross Negligence: Walker throws the book at NSW, Feds over Murray Darling
                            </div>
                        </a>
                        <div className='concerns-note'>Dr Martin Hirst - January 31, 2019</div>
                        <div className='portrait-table-with-small-gap'>
                            <div className='hr'></div>
                        </div>
                        <div className='concerns-link-div'>
                            Visit{' '}
                            <a className='concerns-link' href='https://www.michaelwest.com.au/?s=Murray+Darling'>
                                Michael West Media: Independant Journalists
                            </a>{' '}
                            for more coverage on the Murray Darling.
                        </div>
                    </div>
                </div>
                <div>
                    <a href='https://www.booktopia.com.au/sold-down-the-river-scott-hamilton/book/9781922458124.html'>
                        <div className='book-image' style={{ backgroundImage: `url(${soldDownTheRiverCover})` }} />
                    </a>
                </div>
            </div>
            {WhoAreWe()}
            {Footer()}
        </div>
    );
}

export default OurConcerns;
