import '../css/nav.css';
import '../css/whoarewe.css';
import logoSmall from '../img/logo_small.png';
import us from '../img/us.png';

function WhoAreWe() {
    return (
        <div>
            <div className='whoarewe-top'>
                <div className='whoarewe-section-center'>
                    <div className='icon-image' style={{ backgroundImage: `url(${logoSmall})` }} />
                </div>
                <div className='whoarewe-section-left whoarewe-section-text'>
                    <span className='whoarewe-section-title'>Who are we?</span>
                    <div className='whoarewewhoarewe-section-hr' />
                    <div className='whoarewe-section-emphasis'>
                        My name is Jeremy Connor and this lovely lady pictured with me is my wife Sarah Connor. Together
                        we've created this site and will maintain it to empower you to Vote Clearly in the 2021 Shire of
                        Manjimup local election!
                    </div>
                    <div className='whoarewe-section-normal'>
                        We moved to Manjimup in 2014 after we visited on the Manjimup Ag Show weekend and fell in love
                        with the town! Since then we have made many lifelong friends and immensely enjoyed becomming a
                        part of the Manjimup community.
                    </div>
                </div>
                <div className='whoarewe-section-center'>
                    <div className='us-image' style={{ backgroundImage: `url(${us})` }} />
                </div>
            </div>
        </div>
    );
}

export default WhoAreWe;
