import * as React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
import paulOmodeiPhoto from './img/paul_omodei.png';
import jaydeDarinPhoto from './img/jayde_darin.png';
// import deniseJenkinsPhoto from './img/denise_jenkins.png';
// import robertTaylorPhoto from './img/robert_taylor.png';
// import susanDawsonVidovichPhoto from './img/susan_dawson_vidovich.png';
// import kimSkossPhoto from './img/kim_skoss.png';
import cliffWinfieldPhoto from './img/cliff_winfield.png';
import billSmartPhoto from './img/bill_smart.png';
import deanPhillipsPhoto from './img/dean_phillips.png';
import julianSharpPhoto from './img/julian_sharp.png';
import donelleBueggePhoto from './img/donelle_buegge.png';
import murrayVentrisPhoto from './img/murray_ventris.png';
import wadeDecampoPhoto from './img/wade_decampo.png';
// import kennethLawrencePhoto from './img/kenneth_lawrence.png';
import Ward from './components/Ward';
import Councillor from './components/Councillor';
import ContactType from './components/ContactType';
import ShireWard from './components/ShireWard';
import OurConcerns from './components/OurConcerns';
import { councillorQ1, councillorQ2, councillorQ3, councillorQ4 } from './answers/councillorQuestions';
import { candidateQ1, candidateQ2, candidateQ3 } from './answers/candidateQuestions';
import { wadeDecampoQuestion1, wadeDecampoQuestion2, wadeDecampoQuestion3 } from './answers/wadeDecampo';
import {
    paulOmodeiQuestion1,
    paulOmodeiQuestion2,
    paulOmodeiQuestion3,
    paulOmodeiQuestion4,
} from './answers/paulOmodei';
import {
    jaydeDarinQuestion1,
    jaydeDarinQuestion2,
    jaydeDarinQuestion3,
    jaydeDarinQuestion4,
} from './answers/jaydeDarin';
import {
    cliffWinfieldQuestion1,
    cliffWinfieldQuestion2,
    cliffWinfieldQuestion3,
    cliffWinfieldQuestion4,
} from './answers/cliffWinfield';
import { billSmartQuestion1, billSmartQuestion2, billSmartQuestion3 } from './answers/billSmart';
import { deanPhillipsQuestion1, deanPhillipsQuestion2, deanPhillipsQuestion3 } from './answers/deanPhillips';
import { julianSharpQuestion1, julianSharpQuestion2, julianSharpQuestion3 } from './answers/julianSharp';
import { donelleBueggeQuestion1, donelleBueggeQuestion2, donelleBueggeQuestion3 } from './answers/donelleBuegge';
import {
    murrayVentrisQuestion1,
    murrayVentrisQuestion2,
    murrayVentrisQuestion3,
    murrayVentrisQuestion4,
} from './answers/murrayVentris';
// import { wendyEibyQuestion1, wendyEibyQuestion2, wendyEibyQuestion3, wendyEibyQuestion4 } from './answers/wendyEiby';
import FinancialInterest from './components/FinancialInterest';
import ScrollToTop from './ScrollToTop';

// Central Ward
const paulOmodei = {
    name: 'Paul Omodei',
    email: 'shire.president@manjimup.wa.gov.au',
    url: '/manjimup/councillor/paul-omodei',
    councillor: true,
    photo: paulOmodeiPhoto,
    responded: false,
    answered: false,
    termExpires: 2021,
    question1: null,
    contactType: ContactType.Email,
    details: [
        {
            key: 'a1',
            heading: councillorQ1,
            note: `Meeting minutes suggest Paul did not vote due to a conflict of interest; "Councillor Omodei declared a Financial Interest in Item 13 as a cooperative member, Justin Omodei is his son."`,
            body: paulOmodeiQuestion1,
        },
        { key: 'a2', heading: councillorQ2, body: paulOmodeiQuestion2 },
        { key: 'a3', heading: councillorQ3, body: paulOmodeiQuestion3 },
        { key: 'a4', heading: councillorQ4, body: paulOmodeiQuestion4 },
    ],
    financialInterest: FinancialInterest.Active,
};
const jaydeDarin = {
    name: 'Jayde Darin',
    email: 'jayde.darin@gmail.com',
    url: '/manjimup/councillor/jayde-darin',
    shareUrl: 'jayde-darin',
    councillor: true,
    photo: jaydeDarinPhoto,
    responded: true,
    answered: true,
    termExpires: 2021,
    question1: null,
    question2: true,
    question3: true,
    question4: true,
    contactType: ContactType.Share,
    details: [
        {
            key: 'a1',
            heading: councillorQ1,
            note: 'My first question was not specific enough, but Jayde provides clarification and important detail in her answer below. As recorded in the minutes she did not vote; "Councillor Salomone declared a Financial Interest in Item 13 as she is an employee of Dept. of Biodiversity, Conservation and Attractions."',
            body: jaydeDarinQuestion1,
        },
        { key: 'a2', heading: councillorQ2, body: jaydeDarinQuestion2 },
        { key: 'a3', heading: councillorQ3, body: jaydeDarinQuestion3 },
        { key: 'a4', heading: councillorQ4, body: jaydeDarinQuestion4 },
    ],
    financialInterest: FinancialInterest.Passive,
};
const donelleBuegge = {
    name: 'Donelle Buegge',
    email: 'donellebuegge@gmail.com',
    url: '/manjimup/candidate/donelle-buegge',
    councillor: false,
    shareUrl: 'donelle-buegge',
    photo: donelleBueggePhoto,
    responded: true,
    answered: true,
    question1: null,
    question2: true,
    question3: true,
    question4: true,
    contactType: ContactType.Share,
    details: [
        { key: 'a2', heading: candidateQ1, body: donelleBueggeQuestion1 },
        { key: 'a3', heading: candidateQ2, body: donelleBueggeQuestion2 },
        {
            key: 'a4',
            heading: candidateQ3,
            note: `Whilst Donelle supports sharing water within a catchment she is opposed to commidity water markets which would invite speculators.`,
            body: donelleBueggeQuestion3,
        },
    ],
    financialInterest: FinancialInterest.None,
};
// const deniseJenkins = {
//     name: 'Denise Jenkins',
//     email: 'denisejjenkins@bigpond.com',
//     councillor: true,
//     photo: deniseJenkinsPhoto,
//     responded: false,
//     answered: false,
//     termExpires: 2023,
//     question1: null,
//     contactType: ContactType.Email,
// };
// const robertTaylor = {
//     name: 'Robert Taylor',
//     email: 'taylorrk@bigpond.net.au',
//     councillor: true,
//     photo: robertTaylorPhoto,
//     responded: false,
//     answered: false,
//     termExpires: 2023,
//     question1: null,
//     contactType: ContactType.Email,
// };
// const susanDawsonVidovich = {
//     name: 'Susan Dawson Vidovich',
//     email: 'Susan_Dawson_@hotmail.com',
//     councillor: true,
//     photo: susanDawsonVidovichPhoto,
//     responded: true,
//     answered: false,
//     termExpires: 2023,
//     question1: null,
//     contactType: ContactType.Email,
// };
// North ward
// const kimSkoss = {
//     name: 'Kim Skoss',
//     email: 'kim.skoss1@gmail.com',
//     councillor: true,
//     photo: kimSkossPhoto,
//     responded: true,
//     answered: false,
//     question1: null,
//     termExpires: 2023,
//     contactType: ContactType.Email,
// };
// East ward
const cliffWinfield = {
    name: 'Cliff Winfield',
    email: 'winfield06@bigpond.com',
    url: '/manjimup/councillor/cliff-winfield',
    councillor: true,
    photo: cliffWinfieldPhoto,
    responded: true,
    answered: false,
    question1: null,
    termExpires: 2021,
    contactType: ContactType.Email,
    details: [
        {
            key: 'a1',
            heading: councillorQ1,
            note: 'My first question was not specific enough. Cliff clarified the specifics of the vote and that he did not vote. The meeting minutes suggest this was due to a conflict of interest; "Councillor Winfield declared a Financial Interest in Item 13 as he is a subscriber to the Southern Forest Irrigation Scheme."',
            body: cliffWinfieldQuestion1,
        },
        { key: 'a2', heading: councillorQ2, body: cliffWinfieldQuestion2 },
        { key: 'a3', heading: councillorQ3, body: cliffWinfieldQuestion3 },
        { key: 'a4', heading: councillorQ4, body: cliffWinfieldQuestion4 },
    ],
    financialInterest: FinancialInterest.Active,
};
const billSmart = {
    name: 'Bill Smart',
    email: 'smartbill35@gmail.com',
    url: '/manjimup/candidate/bill-smart',
    councillor: false,
    shareUrl: 'bill-smart',
    photo: billSmartPhoto,
    responded: true,
    answered: true,
    question1: null,
    question2: true,
    question3: true,
    question4: true,
    contactType: ContactType.Share,
    details: [
        { key: 'a2', heading: candidateQ1, body: billSmartQuestion1 },
        { key: 'a3', heading: candidateQ2, body: billSmartQuestion2 },
        { key: 'a4', heading: candidateQ3, body: billSmartQuestion3 },
    ],
    financialInterest: FinancialInterest.None,
};
const deanPhillips = {
    name: 'Dean Phillips',
    email: 'Dwphillips-88@outlook.com',
    url: '/manjimup/candidate/dean-phillips',
    councillor: false,
    shareUrl: 'dean-phillips',
    photo: deanPhillipsPhoto,
    responded: true,
    answered: true,
    question1: null,
    question2: false,
    question3: true,
    question4: true,
    contactType: ContactType.Share,
    details: [
        { key: 'a2', heading: candidateQ1, body: deanPhillipsQuestion1 },
        { key: 'a3', heading: candidateQ2, body: deanPhillipsQuestion2 },
        { key: 'a4', heading: candidateQ3, body: deanPhillipsQuestion3 },
    ],
    financialInterest: FinancialInterest.None,
};
// South ward

// West ward
const julianSharp = {
    name: 'Julian Sharp',
    email: 'jsharp@wn.com.au',
    url: '/manjimup/candidate/julian-sharp',
    councillor: false,
    shareUrl: 'julian-sharp',
    photo: julianSharpPhoto,
    responded: true,
    answered: true,
    question1: null,
    question2: true,
    question3: true,
    question4: true,
    contactType: ContactType.Share,
    details: [
        { key: 'a2', heading: candidateQ1, body: julianSharpQuestion1 },
        { key: 'a3', heading: candidateQ2, body: julianSharpQuestion2 },
        { key: 'a4', heading: candidateQ3, body: julianSharpQuestion3 },
    ],
    financialInterest: FinancialInterest.None,
};
const murrayVentris = {
    name: 'Murray Ventris',
    email: 'pembuser@westnet.com.au',
    url: '/manjimup/councillor/murray-ventris',
    councillor: true,
    photo: murrayVentrisPhoto,
    responded: true,
    answered: true,
    question1: false,
    question2: true,
    question3: false,
    question4: undefined,
    termExpires: 2021,
    contactType: ContactType.Email,
    details: [
        {
            key: 'a1',
            heading: councillorQ1,
            body: murrayVentrisQuestion1,
        },
        {
            key: 'a2',
            heading: councillorQ2,
            note: 'I clarified with Murray that he has read some of the CSIRO report and absorbed the keypoints in addition to receiving a briefing on the report.',
            body: murrayVentrisQuestion2,
        },
        {
            key: 'a3',
            heading: councillorQ3,
            note: 'I have recorded Murray as supporting the SFIS in principle because he is not explicitly opposed. The CSIRO report we have already received clearly shows that SFIS modelling is incorrect and in my view justifies formal opposition to the plan.',
            body: murrayVentrisQuestion3,
        },
        {
            key: 'a4',
            heading: councillorQ4,
            note: 'I have recorded Murray as possibly supporting water markets because having concerns is not the same as being opposed to water markets in principle.',
            body: murrayVentrisQuestion4,
        },
    ],
    financialInterest: FinancialInterest.None,
};
const wadeDecampo = {
    name: 'Wade De Campo',
    email: 'wade@decampo.com.au',
    url: '/manjimup/candidate/wade-decampo',
    councillor: false,
    photo: wadeDecampoPhoto,
    responded: true,
    answered: true,
    question1: null,
    question2: true,
    question3: false,
    contactType: ContactType.Email,
    details: [
        {
            key: 'a1',
            heading: 'Do you have a financial interest in the SFIS?',
            body: `No I do or did not directly have any application in for water from the scheme. However my brothers did so in the interest of integrity I would declare an impartiality at the least.`,
        },
        {
            key: 'a2',
            heading: candidateQ1,
            note: `Wade has only read the executive summary, but assuming he accepts those findings he should understand how and why the SFIS modelling provided by DWER is flawed.`,
            body: wadeDecampoQuestion1,
        },
        { key: 'a3', heading: candidateQ2, body: wadeDecampoQuestion2 },
        { key: 'a4', heading: candidateQ3, body: wadeDecampoQuestion3 },
    ],
    financialInterest: FinancialInterest.Relative,
};
// const kennethLawrence = {
//     name: 'Kenneth Lawrence',
//     email: 'info@beedelup.com.au',
//     councillor: true,
//     photo: kennethLawrencePhoto,
//     responded: false,
//     answered: false,
//     question1: false,
//     termExpires: 2023,
//     contactType: ContactType.Email,
// };
// Coastal ward
// const wendyEiby = {
//     name: 'Wendy Eiby',
//     email: 'wendy.eiby8@bigpond.com',
//     url: '/manjimup/councillor/wendy-eiby',
//     councillor: true,
//     photo: wendyEibyPhoto,
//     responded: false,
//     answered: false,
//     question1: false,
//     termExpires: 2021,
//     contactType: ContactType.Email,
//     details: [
//         {
//             key: 'a1',
//             heading: question1,
//             body: wendyEibyQuestion1,
//         },
//         { key: 'a2', heading: question2, body: wendyEibyQuestion2 },
//         { key: 'a3', heading: question3, body: wendyEibyQuestion3 },
//         { key: 'a4', heading: question4, body: wendyEibyQuestion4 },
//     ],
//     financialInterest: FinancialInterest.None,
// };

export default function App() {
    React.useEffect(() => {
        // pre-fetch images when the app loads so they are available asap
        [jaydeDarinPhoto, billSmartPhoto, murrayVentrisPhoto, cliffWinfieldPhoto, paulOmodeiPhoto].forEach(
            (picture) => {
                const img = new Image();
                img.src = picture;
            }
        );
    });

    return (
        <div>
            <Router basename='/'>
                <ScrollToTop />
                <Switch>
                    <Route path={'/manjimup/our-concerns'} exact>
                        {OurConcerns}
                    </Route>
                    <Route path={'/manjimup/ward/central'} exact>
                        {Ward({
                            ward: ShireWard.Central,
                            councillors: [donelleBuegge, jaydeDarin, paulOmodei],
                        })}
                    </Route>
                    {/* <Route path={'/manjimup/ward/north'} exact>
                        {Ward({
                            ward: ShireWard.North,
                            councillors: [],
                        })}
                    </Route> */}
                    <Route path={'/manjimup/ward/east'} exact>
                        {Ward({
                            ward: ShireWard.East,
                            councillors: [billSmart, deanPhillips, cliffWinfield],
                        })}
                    </Route>
                    {/* <Route path={'/manjimup/ward/south'} exact>
                        {Ward({
                            ward: ShireWard.South,
                            councillors: [],
                        })}
                    </Route> */}
                    <Route path={'/manjimup/ward/west'} exact>
                        {Ward({
                            ward: ShireWard.West,
                            councillors: [julianSharp, murrayVentris, wadeDecampo],
                        })}
                    </Route>
                    {/* <Route path={'/manjimup/ward/coastal'} exact>
                        {Ward({
                            ward: ShireWard.Coastal,
                            councillors: [wendyEiby],
                        })}
                    </Route> */}
                    <Route path={'/manjimup/councillor/paul-omodei'} exact>
                        {Councillor(paulOmodei)}
                    </Route>
                    <Route path={'/manjimup/councillor/jayde-darin'} exact>
                        {Councillor(jaydeDarin)}
                    </Route>
                    <Route path={'/manjimup/candidate/donelle-buegge'} exact>
                        {Councillor(donelleBuegge)}
                    </Route>
                    <Route path={'/manjimup/councillor/cliff-winfield'} exact>
                        {Councillor(cliffWinfield)}
                    </Route>
                    <Route path={'/manjimup/candidate/julian-sharp'} exact>
                        {Councillor(julianSharp)}
                    </Route>
                    <Route path={'/manjimup/councillor/murray-ventris'} exact>
                        {Councillor(murrayVentris)}
                    </Route>
                    <Route path={'/manjimup/candidate/wade-decampo'} exact>
                        {Councillor(wadeDecampo)}
                    </Route>
                    {/* <Route path={'/manjimup/councillor/wendy-eiby'} exact>
                        {Councillor(wendyEiby)}
                    </Route> */}
                    <Route path={'/manjimup/candidate/bill-smart'} exact>
                        {Councillor(billSmart)}
                    </Route>
                    <Route path={'/manjimup/candidate/dean-phillips'} exact>
                        {Councillor(deanPhillips)}
                    </Route>
                    {/* Default route for anything unmatched */}
                    <Route path='*'>
                        <Redirect to={'/manjimup/ward/central'} />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}
